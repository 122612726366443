/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.ion-color-dangerv2 {
  --ion-color-base: var(--ion-color-dangerv2);
  --ion-color-base-rgb: var(--ion-color-dangerv2-rgb);
  --ion-color-contrast: var(--ion-color-dangerv2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dangerv2-contrast-rgb);
  --ion-color-shade: var(--ion-color-dangerv2-shade);
  --ion-color-tint: var(--ion-color-dangerv2-tint);
}

.ion-color-successv2 {
  --ion-color-base: var(--ion-color-successv2);
  --ion-color-base-rgb: var(--ion-color-successv2-rgb);
  --ion-color-contrast: var(--ion-color-successv2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-successv2-contrast-rgb);
  --ion-color-shade: var(--ion-color-successv2-shade);
  --ion-color-tint: var(--ion-color-successv2-tint);
}

.ion-color-tertiaryv2 {
  --ion-color-base: var(--ion-color-tertiaryv2);
  --ion-color-base-rgb: var(--ion-color-tertiaryv2-rgb);
  --ion-color-contrast: var(--ion-color-tertiaryv2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiaryv2-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiaryv2-shade);
  --ion-color-tint: var(--ion-color-tertiaryv2-tint);
}

.ion-color-tw-stone {
  --ion-color-base: var(--ion-color-tw-stone);
  --ion-color-base-rgb: var(--ion-color-tw-stone-rgb);
  --ion-color-contrast: var(--ion-color-tw-stone-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tw-stone-contrast-rgb);
  --ion-color-shade: var(--ion-color-tw-stone-shade);
  --ion-color-tint: var(--ion-color-tw-stone-tint);
}

.ion-color-full-white {
  --ion-color-base: var(--ion-color-full-white);
  --ion-color-base-rgb: var(--ion-color-full-white-rgb);
  --ion-color-contrast: var(--ion-color-full-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-full-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-full-white-shade);
  --ion-color-tint: var(--ion-color-full-white-tint);
}

.border-x {
  border: 1px solid var(--ion-color-tw-stone-tint);
}

::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.alert60 .alert-wrapper {
  min-width: 30vw;
  max-width: 90vw;
}

.custom-modal::part(content) {
  min-width: 90vw;
  max-width: 100vw;
  width: 90vw;
  height: 70vh;
}